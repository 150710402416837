const levelRanges = {
    V: { min: 40, max: 100 },
    M: { min: 4, max: 10 },
    A: { min: 4, max: 10 },
    B: { min: 4, max: 10 },
};

export const isValidGradeValue = ({ subjectId, gradeType, value, level }) => {
    value = parseInt(value);

    if (value === '' || !value) {
        return true;
    }

    const range = levelRanges[level];

    // dropdown
    if (!level) {
        return true;
    }

    if (!range) {
        console.log(`unhandled validation level: ${level}`);
        return true;
    }

    if (value >= range.min && value <= range.max) {
        return true;
    }

    // console.log({ subjectId, gradeType, value, level })
};
